import React, { useEffect, useState } from "react";
import { IoMdAddCircle } from "react-icons/io";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer";
import SectionHeader from "../Components/SectionHeader";
import { questions } from "../Assets/Data/static_data";
import { GrSubtractCircle } from "react-icons/gr";

const FAQs = () => {
  const [showIndex, setShowIndex] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar home={false} />
      <section className="min-h-[71vh] flex items-start justify-start flex-col w-full bg-slate-100">
        <div className="w-full text-center px-3 py-6">
          <SectionHeader
            customClasses="!text-[--blue-dark] "
            text="FREQUENTLY ASKED QUESTIONS (FAQS)"
          />
        </div>

        <div className="w-full lg:w-[80%] max-w-[1800px] mx-auto px-6 py-12">
          {questions.map((element, index) => {
            return (
              <div key={index} className="border-t border-gray-300">
                <div className="flex-between gap-6 w-full py-8 px-2">
                  <h4 className="text-[14px] sm:text-[16px] md:text-[20px]">
                    {element.text}
                  </h4>
                  <div>
                    {showIndex === index ? (
                      <GrSubtractCircle
                        onClick={() => {
                          setShowIndex(null);
                        }}
                        className=" cursor-pointer text-xl sm:text-2xl text-red-500"
                      />
                    ) : (
                      <IoMdAddCircle
                        onClick={() => {
                          setShowIndex(index);
                        }}
                        className="cursor-pointer text-xl sm:text-2xl text-[--blue-light]"
                      />
                    )}
                  </div>
                </div>
                {showIndex === index ? (
                  <div className="w-full px-2 pb-4">
                    <p className="text-[12px] sm:text-[14px] md:text-[16px] lg:text-[17px]">
                      {element.description}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FAQs;
