import React, { useState } from "react";
import logo from "../../Assets/Images/logo.png";
import { navlinks } from "./linkdata";
import { CgMenuRight } from "react-icons/cg";
import { RiCloseLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const Navbar = ({ handleModal, home }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <nav className="w-full py-4 fixed top-0 h-[70px] left-0 z-50 bg-white">
        <div className="flex-between px-6 ">
          <div className="h-[35px] w-[150px] md:h-[25px] md:w-[100px] lg:h-[35px] lg:w-[150px]">
            <img className="w-full h-full" src={logo} alt="logo here" />
          </div>
          <div className="hidden md:flex justify-between items-center w-[40%]">
            {navlinks.map((item, index) => {
              return (
                <li
                  key={index}
                  className="text-[--blue-dark] text-[16px] capitalize "
                >
                  {item.isAnchor && home ? (
                    <a className="poppin-500 text-[16px]" href={item.url}>
                      {item.text}
                    </a>
                  ) : (
                    <Link className="poppin-500 text-[16px]" to={"/"}>
                      {item.text}
                    </Link>
                  )}
                </li>
              );
            })}
          </div>
          <div className="hidden md:flex justify-center items-center">
            <button
              onClick={handleModal}
              className="text-[16px] md:text-[14px] lg:text-[16px] text-[--blue-dark]  rounded-full px-10 md:px-6 lg:px-10 py-2 border border-[--blue-dark] poppin-500"
            >
              Login
            </button>
            <button
              onClick={handleModal}
              className="text-[16px] md:text-[14px] lg:text-[16px] text-white bg-[--blue-dark] ml-3 rounded-full px-4 py-2 border border-[--blue-dark] poppin-500"
            >
              Get Started
            </button>
          </div>
          <div className="cursor-pointer flex justify-center items-center md:hidden text-center relative z-50 text-3xl sm:text-4xl  text-[--blue-dark] ">
            {menuOpen ? (
              <RiCloseLine
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
              />
            ) : (
              <CgMenuRight
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
              />
            )}
          </div>
        </div>
        <div
          className={`${
            menuOpen ? "menu-open" : "close-menu"
          } w-full md:w-1/2 rounded-xl bg-[--blue-dark] pt-4 pb-6 flex justify-center items-center md:hidden  relative z-40`}
        >
          <div customStyle="flex-col w-full items-center text-center">
            {navlinks.map((item, index) => {
              return (
                <li key={index} className="text-center capitalize py-5">
                  {item.isAnchor && home ? (
                    <a
                      className="poppin-500 text-[16px] text-white"
                      href={item.url}
                    >
                      {item.text}
                    </a>
                  ) : (
                    <Link
                      className="poppin-500 text-[16px] text-white"
                      to={"/"}
                    >
                      {item.text}
                    </Link>
                  )}
                </li>
              );
            })}

            <div className="flex justify-center items-center gap-6">
              <button
                onClick={handleModal}
                className="text-[13px] text-white  rounded-full px-10 md:px-6 lg:px-10 py-2 border border-white poppin-500 transition-all ease-in-out duration-300 hover:bg-white hover:text-[--blue-dark]"
              >
                Login
              </button>
              <button
                onClick={handleModal}
                className="text-[13px] text-white ml-3 rounded-full px-4 py-2 border border-white poppin-500 transition-all ease-in-out duration-300 hover:bg-white hover:text-[--blue-dark]"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div className="w-full h-[60px] bg-white"></div>
    </>
  );
};

export default Navbar;
