import React from "react";
import logo from "../Assets/Images/logo.png";
import { footerIcons, footerLinks } from "../Assets/Data/static_data";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="flex justify-center items-center flex-col py-4 w-full bg-[--blue-dark]">
      <div className="w-full lg:w-[90%] px-6 lg:px-0 flex justify-center items-center flex-col sm:flex-row sm:justify-between sm:items-center border-b border-gray-500 py-3">
        <div className="bg-white py-1 px-2 rounded-lg">
          <img
            className="h-[25px] w-[80px] sm:h-[20px] md:h-[30px] sm:w-[70px]  md:w-[100px] lg:w-[150px]"
            src={logo}
            alt="logo here"
          />
        </div>
        <div className="flex-between flex-wrap mt-4 sm:mt-0 ml-[10px] sm:ml-0 sm:flex-nowrap gap-3  md:gap-5 lg:gap-8 xl:gap-12">
          {footerLinks.map((item, index) => {
            return (
              <li
                key={index}
                className="text-white text-[8px] sm:text-[8px] md:text-[10px] lg:text-[12px] xl:text-[14px]"
              >
                <Link to={item.url} className="capitalize">
                  {item.label}
                </Link>
              </li>
            );
          })}
        </div>
      </div>
      <div className="w-full text-white lg:w-[90%] px-6 lg:px-0 flex justify-center items-center flex-col-reverse sm:flex-row sm:justify-between sm:items-center  pt-3">
        <div className="mt-3 sm:mt-0">
          <h6 className="poppin-500 text-[10px] sm:text-[12px] lg:text-[16px]">
            © Copyright NesMasPoint 2023
          </h6>
        </div>
        <div className="flex-center flex-nowrap">
          <h6 className=" poppin-500 text-[10px] sm:text-[12px] lg:text-[16px] w-max">
            Find us on social media :
          </h6>
          <div className="flex-between flex-wrap gap-4 sm:gap-5 md:gap-6 lg:gap-12 pl-8 sm:pl-6 lg:pl-10">
            {footerIcons.map((item, index) => {
              return (
                <a key={index} href={item.url}>
                  <img
                    className="w-[15px] h-[15px] sm:w-[25px] sm:h-[25px]"
                    src={item.imageRef}
                    alt="icon here"
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
