import React, { useState } from "react";
import { IoMdAddCircle } from "react-icons/io";
import { questions } from "../Assets/Data/static_data";
import { IoArrowForward } from "react-icons/io5";
import { GrSubtractCircle } from "react-icons/gr";
import { Link } from "react-router-dom";

const Contact = () => {
  const [showIndex, setShowIndex] = useState(false);

  return (
    <div className="w-full flex-between flex-col lg:flex-row !items-stretch pt-8 lg:pt-16 pb-5">
      <div className="w-full lg:w-1/2 px-10">
        {questions.slice(0, 4).map((element, index) => {
          return (
            <div key={index} className="border-t border-gray-300">
              <div className="flex-between gap-6 w-full py-8 px-2">
                <h4 className="text-[14px] sm:text-[16px] md:text-[20px]">
                  {element.text}
                </h4>
                <div>
                  {showIndex === index ? (
                    <GrSubtractCircle
                      onClick={() => {
                        setShowIndex(null);
                      }}
                      className=" cursor-pointer text-xl sm:text-2xl text-red-500"
                    />
                  ) : (
                    <IoMdAddCircle
                      onClick={() => {
                        setShowIndex(index);
                      }}
                      className="cursor-pointer text-xl sm:text-2xl text-[--blue-light]"
                    />
                  )}
                </div>
              </div>
              {showIndex === index ? (
                <div className="w-full px-2 pb-4">
                  <p className="text-[12px] sm:text-[14px] md:text-[16px] lg:text-[17px]">
                    {element.description}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
      <div className=" w-full lg:w-1/2 px-0 pl-10 lg:pl-0 lg:px-8 border-t pt-8 lg:pt-0 lg:border-none">
        <h1 className="font-[okatah-700] text-[18px] sm:text-[24px]">
          How we can help you?
        </h1>
        <p className="text-[14px] sm:text-[16px] text-gray-500 pr-12 mt-8">
          We are here to assist you in various ways! Whether you have questions,
          need information, want help with problem-solving, or seeking creative
          ideas for your business, feel free to let us know.
        </p>
        <div className="flex justify-start items-center mt-8 w-full">
          <input
            type="email"
            placeholder="Enter your email"
            className="w-[60%] py-3 px-3 rounded-full border text-black text-[10px] sm:text-[13px] md:text-[16px] placeholder:text-gray-300 outline-none"
          />
          <button className="bg-[--blue-dark] text-white  text-[10px] sm:text-[13px] md:text-[16px] rounded-full py-3 ml-3 px-3 sm:px-6 md:px-8">
            Lets Talk
          </button>
        </div>
        <div className="mt-12 text-[--blue-light]">
          <Link
            className="flex text-[15px] sm:text-[18px] justify-start items-center"
            to="/frequently-asked-questions"
          >
            More FAQ{" "}
            <span>
              <IoArrowForward className="ml-2 text-xl" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contact;
