import React from "react";

const Typograpghy = ({ customclasses, children }) => {
  return (
    <div className={`mt-2 ${customclasses}`}>
      <p className="text-[14px] sm:text-[16px]">{children}</p>
    </div>
  );
};

export default Typograpghy;
