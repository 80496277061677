import React from "react";
import { RiCloseFill } from "react-icons/ri";
import logo from "../Assets/Images/logo.png";
import { storeData } from "../Assets/Data/static_data";

const Modal = ({ open, handleModal }) => {
  return (
    <section
      className={`${open ? "w-screen h-screen modal flex-center" : "hidden"}`}
    >
      <div className="bg-white w-[90%] sm:w-[70%] md:w-[70%] max-w-[600px] rounded-lg p-6 pb-8">
        <div className="flex justify-end items-center">
          <RiCloseFill
            onClick={handleModal}
            className="text-3xl font-bold cursor-pointer text-red-500"
          />
        </div>
        <div className="w-full flex-center my-2">
          <img src={logo} className="lg:w-[220px] lg:h-[50px]" alt="" />
        </div>
        <div className="mt-4 text-center">
          <p className="text-[--gray-dark] text-[16px]">
            NesMasPoint is a one stop business solution for your business. We
            always committed to provide the best service to grow your business
            easily
          </p>
        </div>

        {/* play store and app store  */}
        <div className="flex justify-evenly items-center  mt-5 w-full">
          {storeData.map((item, index) => {
            return (
              <a
                key={index}
                href={item.url}
                className="bg-black text-white px-5 py-1 md:py-2 lg:py-2 rounded-xl flex-between"
              >
                <img
                  className="w-[13px] h-[13px] sm:w-[25px] sm:h-[25px] sm md:w-[30px] md:h-[30px] lg:w-[35px] lg:h-[35px]"
                  src={item.imageRef}
                  alt={`${item.name} icon`}
                />
                <a href="/" className="ml-2 sm:ml-4 md:ml-6">
                  <p className="uppercase poppin-500 text-[7px] sm:text-[10px] md:text-[12px] lg:text-[13px]">
                    Get it on
                  </p>
                  <h1 className="poppin-700 text-[9px] sm:text-[12px] md:text-[14px] lg:text-[15px]">
                    {item.name}
                  </h1>
                </a>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Modal;
