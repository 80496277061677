import React from "react";
import SectionHeader from "./SectionHeader";
import WhyUsCard from "./WhyUsCard";

const WhyUs = () => {
  return (
    <div className="w-full p-4">
      <div className="w-[90%] md:w-[80%] lg:w-[60%] mx-auto py-3 mt-10 text-center">
        <SectionHeader text="Why Choose Us?" />
        <p className="mt-3 w-full text-[16px] sm:text-[18px] md:text-[20px]">
          We always committed to provide the best services to our valuable
          customers for the below mentioned reason we are best and you can
          choose us.
        </p>
      </div>
      <div className="w-[90%] mx-auto flex-center !items-stretch  py-16 flex-wrap gap-12">
        <WhyUsCard
          header="Comprehensive Business Management"
          description="NesMasPoint offers a robust business management suite tailored for SMBs, covering various aspects like inventory, sales, and customer relationship management, providing a holistic solution to streamline operations."
        />

        <WhyUsCard
          header=" E-Commerce Platform"
          description="With NesMasPoint, customers not only get traditional business management features but also benefit from a seamlessly e-commerce platform. This allows merchants to expand their business reach and engage with customers online effortlessly."
        />
        <WhyUsCard
          header="Responsive Customer Support"
          description="NesMasPoint is backed by responsive 24/7 customer support, helping when needed. This ensures that businesses using the software have a reliable support system to address any issues promptly, minimizing disruptions to their operations."
        />
        <WhyUsCard
          header="Security and Data Protection"
          description="Prioritising the security of sensitive business information, NesMasPoint implements robust data protection measures. This ensures that customer data, financial records, and other critical information are safeguarded, instilling trust and compliance with data protection regulations."
        />
      </div>
    </div>
  );
};

export default WhyUs;
