import React from "react";

const WhyUsCard = ({ header, description }) => {
  return (
    <div className=" flex-grow basis-[400px]  py-6 px-8 rounded-lg bg-[#F7F8FB]">
      <h2 className="poppin-600 text-[18px]  md:text-[20px] lg:text-[24px]">
        {header}
      </h2>
      <p className="text-[14px] md:text-[18px] mt-3">{description}</p>
    </div>
  );
};

export default WhyUsCard;
