export const termsandconditions = [
  {
    heading: "LICENSE AND USE",
    numbering: true,
    isBullets: false,
    paragrapghs: [
      {
        text: "License: We grant you a non-exclusive, non-transferable, and revocable license to use the Software by these Terms.",
      },
      {
        text: "Restrictions: You may not sublicense, rent, lease, sell, or distribute the Software. You may not modify, reverse engineer, decompile, or disassemble the Software or attempt to gain unauthorised access to the Software's source code.",
      },
    ],
  },
  {
    heading: "USER ACCOUNTS",
    numbering: true,
    isBullets: false,
    paragrapghs: [
      {
        text: "Account Creation: To use certain features of the Software, you may be required to create a user account. You are responsible for maintaining the security of your account and any actions taken under your account.",
      },
    ],
  },
  {
    heading: "USER ACCOUNTS",
    numbering: true,
    isBullets: false,
    paragrapghs: [
      {
        text: "Privacy Policy: Your use of the Software is also governed by our Privacy Policy, which can be found on our website.",
      },
    ],
  },
  {
    heading: "PAYMENT AND FEES",
    isBullets: false,
    numbering: true,

    paragrapghs: [
      {
        text: "Fees: Your use of the Software is subject to payment of fees, as detailed in our pricing plans. You agree to pay all applicable fees as per your subscription.",
      },
      {
        text: "Refunds: We do not provide refunds for fees paid.",
      },
    ],
  },
  {
    heading: "TERMINATION",
    isBullets: false,
    numbering: true,
    paragrapghs: [
      {
        text: "Termination by You: You may stop using the Software anytime. Termination does not entitle you to a refund of any fees paid.",
      },
      {
        text: "Termination by You: You may stop using the Software anytime. Termination does not entitle you to a refund of any fees paid.",
      },
    ],
  },
  {
    heading: "UPDATES AND CHANGES",
    isBullets: false,
    numbering: false,
    paragrapghs: [
      {
        text: "We may release updates, modifications, or new versions of the Software, and these may be subject to additional terms.",
      },
    ],
  },
  {
    heading: "GOVERNING LAW",
    isBullets: false,
    numbering: false,
    paragrapghs: [
      {
        text: "These Terms are governed by and construed under the laws of Nigeria.",
      },
    ],
  },
  {
    heading: "USER CONDUCT",
    isBullets: true,
    numbering: false,
    paragrapghs: [
      {
        text: "BY USING THIS SOFTWARE, YOU AGREE THAT YOU ARE 18 YEARS AND ABOVE, AND YOU WILL NOT AS A MERCHANT SELL, POST, OR EMAIL ANY OF THESE:",
      },
    ],
    bullets: [
      {
        text: "Hard drugs and medications that require a prescription from a licensed medical professional.",
      },
      { text: "Restricted military items, Police items and Weapons." },
      { text: "Illegal or pirated copies of an item(s)." },
      { text: "Stolen properties." },
      { text: "Items that are prohibited by law." },
      { text: "Services of a sexually oriented nature." },
      { text: "Human Parts." },
      {
        text: "Products, offers, or services that are generally prohibited from being sold by law are not allowed.",
      },
      { text: "Defamatory materials." },
      { text: "Misleading and deceitful materials." },
      { text: "Promotes or instigates religious and ethnic conflicts." },
      { text: "Discriminates the disabled or religion or tribe." },
      {
        text: "Contacting users with their information provided on NesMasPoint for other reasons.",
      },
      { text: "Infringes rights of third parties." },
      { text: "Violates users’ privacy." },
      { text: "Item without legal rights." },
      { text: "That contains a virus." },
      { text: "Harmful to the body." },
      { text: "False information." },
      { text: "Overpriced items." },
    ],
  },
  {
    heading: "ABUSE",
    isBullets: false,
    numbering: false,
    paragrapghs: [
      {
        text: "It is required that NesMasPoint and its users collaborate to maintain the proper functioning of the platform and ensure the safety of everyone. You should report issues, offensive content, or violations of the policies to us as soon as you notice any.",
      },
      {
        text: "While we have the right to take various actions such as issuing warnings, imposing limitations, or termination of services and utilising our discretion to address non-compliant usage, as well as implementing technical and legal measures to prevent problematic merchants and users from accessing this software, we do not assume any liability for monitoring the platform or for unauthorised or unlawful products posted or being sold by merchants.",
      },
    ],
  },
  {
    heading: "DISCLAIMER",
    isBullets: false,
    numbering: false,
    paragrapghs: [
      {
        text: "YOU AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR YOUR SAFETY AND TRANSACTIONS ON THIS PLATFORM WITH THIRD PARTIES OR MERCHANTS.",
      },
      {
        text: 'We provide all services on an "as is," and "as available," basis and we solely disclaim any warranties, expressed or implied. This includes warranties regarding condition, quality, lifespan, performance, accuracy, reliability, value, and suitability for specific products listed or being sold on this NesMasPoint.',
      },
      {
        text: "We have no control and cannot ensure the presence, quality, safety, or legality of goods and services posted by merchants on this platform. The reliability or accuracy of information shared by merchants in announcements, the capability of merchants to sell goods or offer services, the ability of buyers to make payments for goods or services, and the successful completion of transactions by users are not guaranteed by us.",
      },
      {
        text: "We do not guarantee that the goods and services do not infringe on the third parties´ rights.",
      },
      {
        text: "You are solely responsible for conducting due diligence on any individual or organisation requesting a meeting for a transaction completion.",
      },
      {
        text: "We disclaim any responsibility for a merchant and user's interaction with any individual or organisation.",
      },
      {
        text: "We do not have any influence on tax, which means merchants and users are liable to pay taxes (when applicable).",
      },
      {
        text: "Therefore, you acknowledge and agree that we cannot be held accountable for the posts or actions of any merchant, user or third parties on this platform.",
      },
    ],
  },
  {
    heading: "INFRINGEMENT",
    isBullets: false,
    numbering: false,
    paragrapghs: [
      {
        text: "NesMasPoint values and acknowledges the intellectual property of its users and others and expects its users to do the same.",
      },
      {
        text: "Users need to respect and protect the intellectual property rights of others to avoid infringing upon their rights and facing legal repercussions. Posting of content or products that infringe on third parties' rights is strictly prohibited on this platform.",
      },
      {
        text: "We advise users to report any case of infringement, as we will treat it accordingly.",
      },
    ],
  },
  {
    heading: "INTELLECTUAL PROPERTY",
    isBullets: false,
    numbering: false,

    paragrapghs: [
      {
        text: "NesMasPoint owns or holds the necessary licenses for its software and content, including all copyrights, trademarks, patents, and other proprietary rights.",
      },
      {
        text: "You are strictly prohibited from using any of these materials belonging to NesMasPoint without obtaining written approval from NesMasPoint. Using of user’s intellectual property without his/her consent is strictly forbidden on this platform.",
      },
    ],
  },
  {
    heading: "LIMITATION OF LIABILITY",
    isBullets: false,
    numbering: false,
    paragrapghs: [
      {
        text: "NesMasPoint shall in no event be LIABLE for any direct, indirect, incidental, special, consequential, or exemplary damages that may occur because of your use of the platform.",
      },
      {
        text: "Nevertheless, if in any case we are found liable arising from using our service, our liability to you or a third party is limited to (a) a lesser amount that you have paid to US during the TWELVE (12) MONTH period before the cause of the arising liability or (b) NGN1000.",
      },
    ],
  },
  {
    heading: "INDEMNITY",
    isBullets: false,
    numbering: false,
    paragrapghs: [
      {
        text: "You agree to indemnify and hold NesMasPoint, its officers, licensors and partners, subsidiaries, affiliates, successors, assigns, directors, agents, representatives, service providers, suppliers and employees, harmless from any claim or demand, including attorney fees and court costs, made by any third party due to or arising out of the products you publish or make available through the platform, your use of our services, your violation of the Terms and Conditions, your breach of any of the representations and warranties herein, or your violation of any rights of another.",
      },
      {
        text: "You agree that no indemnity will be awarded by NesMasPoint to third parties, and you shall solely be responsible for that.",
      },
    ],
  },
  {
    heading: "CONTACT INFORMATION",
    isBullets: false,
    numbering: false,
    paragrapghs: [
      {
        text: "If you have any questions or concerns about these Terms, please contact us.",
      },
    ],
  },
];

export const accountCreationFields = [
  {
    text: "Full name: The user's complete name is requested during the account creation process.",
  },
  {
    text: "Email address: Users are asked to provide their email address, which will be associated with their account.",
  },
  {
    text: "Password: A secure password is required to protect the user's account and ensure confidentiality.",
  },
  {
    text: "Contact information: Users may be asked to provide additional contact details, such as a phone number or address, to facilitate communication with the NesMasPoint administrator.",
  },
  {
    text: "Profile information: Users have the option to provide additional information about themselves, such as a profile picture, bio, or other personal details.",
  },
  {
    text: "Payment details: Users may need to provide payment information, such as credit card details or a PayPal account, for subscription. Note, that we do not save your payment information.",
  },
  {
    text: "Preferences: Users may have the opportunity to customize their account settings, and notification preferences.",
  },
];

export const sharingInformation = [
  {
    text: "Government bodies and law enforcement agencies, to comply with the law.",
  },
  {
    text: "Professional advisers, to enforce or defend our legal rights.",
  },
  {
    text: "With a purchaser or seller in connection with a corporate event such as a merger, business acquisition, or insolvency situation.",
  },
];

export const dataProtectionStatements = [
  {
    text: "We will only retain personal information as long as necessary for the fulfilment of those purposes.",
  },
  {
    text: "We collect and process personal data by applicable data protection laws and regulations.",
  },
  {
    text: "The personal data we collect is used for specific purposes and only with your consent or as permitted by law.",
  },
  {
    text: "We implement appropriate security measures to protect your data from unauthorized access, disclosure, alteration, or destruction.",
  },
  {
    text: "We may share your data with third parties only when necessary and with your explicit consent or as required by law.",
  },
  {
    text: "We will share your data with payment providers and local banks.",
  },
  {
    text: "You have the right to access, rectify, or delete your data, as well as the right to restrict or object to its processing, subject to legal limitations.",
  },
  {
    text: "We provide you with clear and transparent information about our data collection and processing practices through this data protection statement.",
  },
  {
    text: "We may use cookies or similar technologies to enhance your browsing experience and personalize the services we offer.",
  },
  {
    text: "By using our services, you acknowledge and agree to the terms of this data protection statement.",
  },
];

export const cookieUsage = [
  {
    text: "Session management: Cookies enable websites to identify users and remember their unique login information and preferences, such as their preference for sports news or politics.",
  },
  {
    text: "Personalisation: Cookies play a key role in personalising your browsing experience by facilitating customized advertising. They analyse your interactions with a website, such as the items you view, and utilise this information to deliver targeted advertisements that align with your interests.",
  },
  {
    text: "Tracking: Shopping websites utilise cookies to track the products that users have previously viewed. This allows the websites to suggest related items that users might find appealing and keeps items saved in their shopping carts while they continue browsing.",
  },
];
