import FAQs from "./Pages/FAQs";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Terms from "./Pages/Terms";
import Cookie from "./Pages/Cookie";
import Privacy from "./Pages/Privacy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/terms&conditions" element={<Terms />} />
        <Route path="/cookies&policies" element={<Cookie />} />
        <Route path="/privacy&policies" element={<Privacy />} />
        <Route path="/frequently-asked-questions" element={<FAQs />} />
      </Routes>
    </Router>
  );
}

export default App;
