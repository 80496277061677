import React from "react";

const TestimonialCard = ({ imageRef, name, role, comment }) => {
  return (
    <div className="min-w-[320px] max-w-[450px] bg-white cursor-default text-[--gray-dark] rounded-3xl py-5 px-8 transition-all ease-in-out duration-300 hover:bg-[--blue-dark] hover:text-white ">
      <div className="flex justify-start items-center">
        <img
          src={imageRef}
          className="w-[40px] h-[40px] sm:w-[45px] sm:h-[45px] md:w-[60px] md:h-[60px] rounded-full object-cover"
          alt="User Avatar"
        />
        <div className="ml-4">
          <h1 className="poppin-500 text-[14px] sm:text-[17px]  md:text-[20px] lg:text-[24px]">
            {name}
          </h1>
          <h6 className="text-[10px] sm:text-[12px] md:text-[14] lg:text-[16px]">
            {role}
          </h6>
        </div>
      </div>
      <div className="mt-4">
        <p className="text-[10px] sm:text-[12px] md:text-[14] lg:text-[16px]">
          {comment}
        </p>
      </div>
    </div>
  );
};

export default TestimonialCard;
