import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Banner from "../Components/Banner";
// import Partners from "../Components/Partners";
import About from "../Components/About";
import Services from "../Components/Services";
import WhyUs from "../Components/WhyUs";
import Testimonial from "../Components/Testimonial";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import Modal from "../Components/Modal";
import Feature from "../Components/Feature";
import Pricing from "../Components/Pricing";

const Home = () => {
  const [modalOpen, setModalOpen] = useState(false);
  function handleModal() {
    setModalOpen(!modalOpen);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Modal open={modalOpen} handleModal={handleModal} />
      <Navbar home={true} handleModal={handleModal} />
      <Banner />
      {/* <Partners /> */}
      <About handleModal={handleModal} />
      <Feature />
      {/* <Pricing /> */}
      <Services />
      <WhyUs />
      <Testimonial />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
