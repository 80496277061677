import React from "react";

const ListWrapper = ({ data }) => {
  return (
    <div className="styledList ml-5 text-[16px] mt-2">
      {data.map((item, index) => {
        return (
          <li key={index} className="mt-1">
            {item.text}
          </li>
        );
      })}
    </div>
  );
};

export default ListWrapper;
