import spotify from "../Images/spotify.png";
import google from "../Images/google.png";
import uber from "../Images/uber.png";
import microsoft from "../Images/microsoft.png";
import shoplify from "../Images/shoplify.png";
import evernote from "../Images/evernote.png";
import adobe from "../Images/adobe.png";
import paypal from "../Images/paypal.png";
import amazon from "../Images/amazon.png";
import asana from "../Images/asana.png";
import youtube from "../Images/youtube.png";
import facebook from "../Images/Facebook.png";
import twitter from "../Images/twitter.png";
import linkedin from "../Images/linkedin.png";
import instagram from "../Images/instagram.png";
import Playstore from "../Images/playstore.png";
import Appstore from "../Images/appstore.png";

export const partnersdata = [
  {
    imageRef: spotify,
  },
  {
    imageRef: google,
  },
  {
    imageRef: uber,
  },
  {
    imageRef: microsoft,
  },
  {
    imageRef: shoplify,
  },
  {
    imageRef: evernote,
  },
  {
    imageRef: adobe,
  },
  {
    imageRef: paypal,
  },
  {
    imageRef: amazon,
  },
  {
    imageRef: asana,
  },
];

export const questions = [
  {
    text: "What is NesMasPoint?",
    description:
      "NesMasPoint is an indispensable business management software designed to streamline business operations for small and medium-sized businesses (SMBs).",
  },
  {
    text: "How can I get started with NesMasPoint?",
    description:
      "You can start by visiting the Google Play Store or Apple Store to download our mobile app and sign up for an account. Once registered, you can access and use our business management software for your business.",
  },
  {
    text: "What types of business can I manage with NesMasPoint?",
    description:
      "NesMasPoint is versatile and can be used to manage various businesses.",
  },
  {
    text: "Is NesMasPoint compatible with mobile devices?",
    description:
      "Yes, you can download our app on Google Play store or Apple Store",
  },
  {
    text: "Is my data secure with NesMasPoint?",
    description:
      "We take data security seriously. NesMasPoint implements physical, electronic, and managerial measures to safeguard and secure the information you provide",
  },
  {
    text: "Does NesMasPoint offer customer support or training resources for users?",
    description:
      "Yes, we offer 24/7 customer support to assist you with any questions or issues you may encounter while using NesMasPoint.",
  },
  {
    text: "Do you offer a free trial period for NesMasPoint?",
    description:
      "Yes, please visit our website to check our current offers and any available free trial options.",
  },
  {
    text: "Can I upgrade from a basic plan to a premium plan subscription package?",
    description:
      "Yes, you can upgrade from a basic plan to a premium plan subscription package anytime as your business grows. ",
  },
  {
    text: "What happens if I want to cancel my NesMasPoint subscription?",
    description:
      "You can cancel your subscription at any time. Please review our terms and conditions for details on refunds and account termination.",
  },
];

export const footerLinks = [
  {
    label: "Get Our Apps",
    url: "/",
  },
  {
    label: "About",
    url: "/",
  },
  {
    label: "FAQ",
    url: "/frequently-asked-questions",
  },
  {
    label: "Terms & Conditions",
    url: "/terms&conditions",
  },
  {
    label: "Cookie Policy",
    url: "/cookies&policies",
  },
  {
    label: "Privacy Policy",
    url: "/privacy&policies",
  },
  {
    label: "Contact & Support",
    url: "/",
  },
];

export const footerIcons = [
  {
    imageRef: youtube,
    url: "https://www.youtube.com",
  },
  {
    imageRef: facebook,
    url: "https://www.facebook.com/profile.php?id=100092978044834",
  },
  {
    imageRef: twitter,
    url: "https://www.twitter.com",
  },
  {
    imageRef: linkedin,
    url: "https://www.linkedin.com/company/nesmaspoint/",
  },
  {
    imageRef: instagram,
    url: "https://instagram.com/nesmaspoint",
  },
];

export const storeData = [
  {
    imageRef: Playstore,
    url: "/",
    name: "Google Play",
  },
  {
    imageRef: Appstore,
    url: "/",
    name: "App Store",
  },
];

export const features = {
  bookkeeping: [
    {
      label: "Record your daily sales",
    },
    {
      label: "Record your income",
    },
    {
      label: "Record your expenses",
    },
    {
      label: "Create and send invoices",
    },
  ],
  store: [
    {
      label: "Create e-commerce store",
    },
    {
      label: "Receive and process orders",
    },
  ],
};

export const pricingdata = [
  {
    recommendation: false,
    name: "Basic Plan",
    cost: "2,000",
    cancelPolicy: "Cancel at anytime!",
    items: [
      {
        label: "Store website",
      },
      {
        label: "Unlimited Product upload",
      },
      {
        label: "Customers’ order processing",
      },
      {
        label: "Bank details",
      },
    ],
  },

  {
    recommendation: true,
    name: "Premium Plan",
    cost: "4,000",
    cancelPolicy: "Cancel at anytime!",
    items: [
      {
        label: "Everything on basic plan",
      },
      {
        label: "Unlimited sales records",
      },
      {
        label: "Unlimited income and expenses records",
      },
      {
        label: "Unlimited invoicing ",
      },
    ],
  },
];
