import React from "react";
import screens from "../Assets/Images/screens.png";
import { storeData } from "../Assets/Data/static_data";

const Banner = () => {
  return (
    <div className="banner  text-white py-10 flex-center flex-col sm:flex-row">
      <div className="w-[90%] sm:w-[60%] pl-3 sm:pl-6 md:pl-8 lg:pl-10 xl:pl-14 pr-4">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScQvMzOvJXh1A82lU1_ONwQBT6OFMrpsprueXSzJcI6W9F1vQ/viewform"
          className="text-[13px] poppin-700 text-[--blue-dark] bg-white  rounded-md px-10 md:px-6 lg:px-10 py-2 border border-white transition-all ease-in-out duration-300 hover:bg-[--blue-dark] hover:text-white"
          target="_blank"
        >
          Join Waiting List
        </a>
        {/* <h1 className="uppercase mt-3 font-[okatah-500] text-[11px] sm:text-[14px] md:text-[17px] lg:text-[20px]">
          ENJOY 30 DAYS FREE TRIAL
        </h1> */}
        <h1 className="text-[26px] sm:text-[44px] md:text-[57px] lg:text-[68px] xl:text-[70px] font-[okatah-700] mt-5">
          One <span className="font-[okatah-700] text-[--yellow]">Package</span>{" "}
          <span className="font-[okatah-700] text-[--cyan]">Great</span>{" "}
          Solution
        </h1>
        <div className=" mt-5  text-clip text-[11px] sm:text-[14px] md:text-[17px] lg:text-[20px] text-black">
          <h5 className="px-4 rounded-lg py-1 w-[94%] sm:w-max bg-white">
            Get the ultimate business solutions
          </h5>
        </div>
        <div className="w-[80%] mt-5">
          <p className="text-[11px] sm:text-[14px] md:text-[17px] lg:text-[20px]">
            NesMasPoint is a one stop business solution for your business. We
            always committed to provide the best service to grow your business
            easily
          </p>
        </div>

        {/* play store and app store wrapper  */}
        <div className="flex-between mt-8 ">
          {storeData.map((item, index) => {
            return (
              <a
                href={item.url}
                key={index}
                className="store px-5 py-1 md:py-2 lg:py-4 rounded-xl flex-between"
              >
                <img
                  className="w-[13px] h-[13px] sm:w-[18px] sm:h-[20px] md:w-[30px] md:h-[30px] lg:w-[40px] lg:h-[40px]"
                  src={item.imageRef}
                  alt={`${item.name} icon`}
                />
                <div className="ml-2 sm:ml-4 md:ml-6">
                  <p className="uppercase poppin-500 text-[8px] sm:text-[12px] md:text-[16px] lg:text-[20px]">
                    Get it on
                  </p>
                  <h1 className="poppin-700 text-[10px] sm:text-[14px] md:text-[18px] lg:text-[24px]">
                    {item.name}
                  </h1>
                </div>
              </a>
            );
          })}
        </div>
      </div>

      <div className="w-[90%] sm:w-[40%] px-4 text-center flex justify-center items-center sm:block">
        <img
          className="w-[200px] h-[230px] sm:w-[280px] sm:h-[320px] md:w-[400px] md:h-[400px] lg:w-[500px] lg:h-[500px] xl:w-[600px] xl:h-[600px]"
          src={screens}
          alt="App screens"
        />
      </div>
    </div>
  );
};

export default Banner;
