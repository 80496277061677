import React, { useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer";
import SectionHeader from "../Components/SectionHeader";
import { termsandconditions } from "../Assets/Data/textData";
import ListWrapper from "../Components/ListWrapper";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar home={false} />
      <section className="min-h-[71vh] flex items-center justify-start flex-col w-full bg-slate-100 px-6">
        <div className="w-full text-center px-3 py-6">
          <SectionHeader
            customClasses="!text-[--blue-dark] "
            text="TERMS AND CONDITIONS"
          />
        </div>
        <div className="w-[96%] md:w-[90%] mx-auto">
          <p>
            These Terms and Conditions ("Terms") constitute a legally binding
            agreement between you (the "Merchant” or “User" or "you") and
            NesMasPoint ("we," "our," or "us"), governing your use of the
            NesMasPoint business management software ("Software"). By using the
            Software, you agree to be bound by these Terms. If you disagree with
            these Terms, please refrain from using the Software.
          </p>
        </div>

        {termsandconditions.map((terms, index) => {
          return (
            <div key={index} className="w-[96%] md:w-[90%] py-5 text-left">
              <span className="poppin-600 text-[16px] flex justify-start items-center uppercase">
                <h2>{index + 1}.</h2>
                <h2 className="ml-3">{terms.heading}</h2>
              </span>
              {terms.paragrapghs.map((para, subIndex) => {
                return terms.numbering ? (
                  <p key={subIndex} className="mt-2">
                    {`${index + 1}.${subIndex + 1}`} {para.text}
                  </p>
                ) : (
                  <p key={subIndex} className="mt-2">
                    {para.text}
                  </p>
                );
              })}

              {terms.isBullets ? (
                <ListWrapper data={terms.bullets} />
              ) : (
                // <div className="styledList ml-5 text-[16px] mt-2">
                //   {terms.bullets.map((bullet, bulletIndex) => {
                //     return (
                //       <li key={bulletIndex} className="mt-1">
                //         {bullet.text}
                //       </li>
                //     );
                //   })}
                // </div>
                ""
              )}
            </div>
          );
        })}
      </section>
      <Footer />
    </>
  );
};

export default Terms;
