import React from "react";

const ServiceCard = ({ heading, description, image }) => {
  return (
    <div className=" flex-grow lg:basis-[300px] xl:basis-[350px]  p-4 rounded-md bg-white">
      <div className="w-full flex-center py-4">
        <img className="w-[80px] h-[80px]" src={image} alt="service icon" />
      </div>
      <div className="w-full text-center py-3">
        <h1 className="text-[20px] font-[okatah-500] capitalize">{heading}</h1>
      </div>
      <div className="px-3 pt-2">
        <p className="text-[--gray-dark] text-[16px] text-center">
          {description}
        </p>
      </div>
    </div>
  );
};

export default ServiceCard;
