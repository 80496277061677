import React, { useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer";
import SectionHeader from "../Components/SectionHeader";
import {
  accountCreationFields,
  cookieUsage,
  dataProtectionStatements,
  sharingInformation,
} from "../Assets/Data/textData";
import ListWrapper from "../Components/ListWrapper";
import Typograpghy from "../Components/Typograpghy";

const Cookie = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar home={false} />
      <section className="min-h-[71vh] flex items-start justify-start flex-col w-full bg-slate-100 pb-8">
        {/* <div className="w-full text-center px-3 py-6">
          <SectionHeader
            customClasses="!text-[--blue-dark] "
            text="PRIVACY POLICY"
          />
        </div>
        <div className="w-[96%] md:w-[90%] mx-auto">
          <Typograpghy>BY USING THE SERVICE, YOU PROMISE US THAT:</Typograpghy>
          <Typograpghy>
            (I) you have read, comprehended, and consented to this Privacy
            Policy
          </Typograpghy>
          <Typograpghy>
            If in a way the privacy policy does not sit well with you and you do
            not agree or are unable to make this promise, you must refrain from
            using the service. In such a situation, if you have created an
            account on this platform, please delete it or reach out to us
            through call, email, or our social media platforms to request the
            deletion of your account and data.
          </Typograpghy>
          <Typograpghy customclasses={"!mt-10"}>
            1. PERSONAL INFORMATION THAT WE COLLECT
          </Typograpghy>
          <Typograpghy>
            Some of the personal information that we collect from users is
            optional, which the user can decide to decline.
          </Typograpghy>
          <ListWrapper data={accountCreationFields} />
          <h4 className="poppin-700 text-[16px] sm:text-[18px] mt-4">
            We may share your data with third parties in certain circumstances.
            We may, for example, share data with:
          </h4>

          <ListWrapper data={sharingInformation} />

          <Typograpghy customclasses={"!mt-10 ml-3"}>
            2. DATA PROTECTION STATEMENT
          </Typograpghy>
          <ListWrapper data={dataProtectionStatements} />
          <Typograpghy customclasses={"!mt-10 ml-3"}>
            3. PRIVACY POLICY MODIFICATION
          </Typograpghy>
          <Typograpghy customclasses="ml-3">
            Our privacy policy is subject to modification without prior notice.
            So, we advise that you check our privacy policy to ensure you are
            updated.
          </Typograpghy>
        </div> */}
        <div className="w-full text-center px-3 py-6">
          <SectionHeader
            customClasses="!text-[--blue-dark] "
            text="COOKIE POLICY"
          />
        </div>
        <div className="w-[96%] md:w-[90%] mx-auto">
          <Typograpghy customclasses={"!mt-2"}>1. WHAT IS COOKIE?</Typograpghy>
          <Typograpghy>
            Cookies are small text files that contain fragments of data, such as
            a username and password and are utilized to recognise your computer
            while you navigate a computer network.
          </Typograpghy>
          <Typograpghy customclasses={"!mt-10"}>
            2. PURPOSE OF COOKIE
          </Typograpghy>
          <ListWrapper data={cookieUsage} />
          <Typograpghy customclasses={"!mt-10"}>
            3. RIGHT TO USE COOKIE
          </Typograpghy>
          <Typograpghy>
            You are not obligated to use cookies, and you retain the right to
            accept or decline them whenever they appear.
          </Typograpghy>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Cookie;
