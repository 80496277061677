import React from "react";
import SectionHeader from "./SectionHeader";
import ServiceCard from "./ServiceCard";
import Inventory from "../Assets/Images/inventory.png";
import Mobile from "../Assets/Images/mobile.png";
import Onboarding from "../Assets/Images/onboarding.png";
import Tracking from "../Assets/Images/tracking.png";

const Services = () => {
  return (
    <div className="w-full pb-4 pt-12 bg-[--blue-light]">
      <div className="w-full text-center">
        <SectionHeader text="Our Services" customClasses={"!text-white"} />
        <p className="mt-3 text-white text-[12px] sm:text-[16px] md:text-[20px]">
          Manage your business with absolute ease.
        </p>
      </div>
      <div className="w-[90%] md:w-[70%] mx-auto flex-center !items-stretch  py-16 flex-wrap gap-12">
        <ServiceCard
          image={Inventory}
          heading="Inventory Management"
          description="NesMasPoint provides a comprehensive inventory management service, helping businesses efficiently track stock levels, manage product variations, and automate reorder processes, reducing the risk of stockouts or overstock situations."
        />

        <ServiceCard
          image={Mobile}
          heading="Mobile Accessibility:"
          description="NesMasPoint provides mobile accessibility, allowing users to manage their business on the go. With our mobile app and responsive design, users can access key features, monitor sales, and stay connected with their business from anywhere, enhancing flexibility and convenience."
        />

        <ServiceCard
          image={Onboarding}
          heading="Training and Onboarding Support"
          description="NesMasPoint offers training and onboarding support to help businesses make a smooth transition to the platform. This service includes training materials, webinars, and assistance during the initial setup, ensuring that users can maximize the benefits of the software."
        />

        <ServiceCard
          image={Tracking}
          heading="Expense Tracking and Reporting"
          description="NesMasPoint includes features for expense tracking and reporting, assisting businesses in monitoring and managing their expenditures. This service contributes to financial transparency and helps businesses make informed budgeting decisions."
        />
      </div>
    </div>
  );
};

export default Services;
