import React from "react";
import SectionHeader from "./SectionHeader";
// import about from "../Assets/Images/about.png";

const About = ({ handleModal }) => {
  return (
    <div id="about" className="w-full flex-center gap-6 py-6">
      {/* <div className="w-[40%] lg:w-[45%] hidden sm:flex justify-center items-center py-6">
        <div className="w-[200px] h-[250px] sm:w-[280px] sm:h-[340px] md:w-[320px] md:h-[390px] lg:w-[400px] lg:h-[470px] flex justify-center items-center image">
          <img
            className="w-[92%] h-[92%] z-10 rounded-lg"
            src={about}
            alt="about us"
          />
        </div>
      </div> */}
      <div className="w-[90%] sm:w-[55%] lg:w-[70%] pr-6 lg:pr-0 px-0 lg:px-12">
        <div className="w-full text-center">
          <SectionHeader text="About Us" />
        </div>
        <div className="w-full mt-3">
          <p className="text-[14px] sm:text-[16px] md:text-[18px]  text-[--gray-dark]">
            NesMasPoint is an indispensable business management software
            designed to streamline business operations for small and
            medium-sized businesses. User-friendly features empower SMBs to
            efficiently handle customer orders, relationship management,
            inventory control, and bookkeeping. NesMasPoint is a cost-effective
            solution, offering scalability and flexibility to help SMBs thrive
            in a competitive market, ultimately boosting productivity and
            profitability.
          </p>
        </div>
        <div className="text-center">
          <button
            onClick={handleModal}
            className="px-6 py-2 mt-8 rounded-lg text-white bg-[--blue-light] text-[12px] sm:text-[14px] md:text-[16px] lg:text-[20px]"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
