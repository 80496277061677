import React from "react";
import SectionHeader from "./SectionHeader";
import { HiArrowLongRight } from "react-icons/hi2";
import { features } from "../Assets/Data/static_data";

const Feature = () => {
  return (
    <div id="feature" className="w-full gap-6 py-10 bg-[--bluish]">
      <div className="w-full text-center">
        <SectionHeader text="Features" customClasses="!text-[--blue-dark]" />
      </div>
      <div className="w-[90%] gap-6 mx-auto flex justify-center items-stretch flex-wrap sm:flex-nowrap mt-6">
        {/* book keeping features  */}
        <div className="flex justify-center  w-full  sm:w-1/2">
          <div>
            <h2 className="poppin-600 text-[16px] sm:text-[20px] md:text-[24px] lg:text-[28px]">
              Bookkeeping
            </h2>
            <div className="mt-4">
              {features.bookkeeping.map((book, index) => {
                return (
                  <li
                    key={index}
                    className="flex justify-start items-center mt-1"
                  >
                    <HiArrowLongRight className="text-3xl text-[--blue-light]" />
                    <h3 className="ml-2 text-[14px] md:text-[16px] lg:text-[18px]">
                      {book.label}
                    </h3>
                  </li>
                );
              })}
            </div>
          </div>
        </div>

        {/* store features  */}
        <div className="flex justify-center w-full sm:w-1/2">
          <div className="mt-5 sm:mt-0">
            <h2 className="poppin-600 text-[16px] sm:text-[20px] md:text-[24px] lg:text-[28px]">
              Store
            </h2>
            <div className="mt-4">
              {features.store.map((book, index) => {
                return (
                  <li
                    key={index}
                    className="flex justify-start items-center mt-1"
                  >
                    <HiArrowLongRight className="text-3xl text-[--blue-light]" />
                    <h3 className="ml-2 text-[14px] md:text-[16px] lg:text-[18px]">
                      {book.label}
                    </h3>
                  </li>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
