export const navlinks = [
  {
    text: "features",
    url: "#feature",
    isAnchor: true,
  },
  {
    text: "pricing",
    url: "#pricing",
    isAnchor: true,
  },
  {
    text: "about",
    url: "#about",
    isAnchor: true,
  },
  {
    text: "blog",
    url: "#blog",
    isAnchor: true,
  },
];
