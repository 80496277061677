import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import TestimonialCard from "./TestimonialCard";
import testimonial1 from "../Assets/Images/testimonial1.png";
import testimonial2 from "../Assets/Images/testimonial2.png";

const Testimonial = () => {
  return (
    <div id="blog" className="w-full py-6 bg-[#EEF4F9]">
      <div className="w-[90%] mx-auto flex-between pt-6 pb-3">
        <h1 className="font-[okatah-700] text-[16px] sm:text-[25px]  md:text-[34px] lg:text-[40px] capitalize text-black">
          What Our Client Said about us
        </h1>
        <div className="flex-center px-2 py-2">
          <button className=" w-[25px] h-[25px] sm:w-[35px] sm:h-[35px] md:w-[45px] md:h-[45px] lg:h-[60px] lg:w-[60px] rounded-full flex-center bg-white transition-all ease-in-out duration-300 hover:bg-[--blue-dark] hover:text-white">
            <FaArrowLeft className="text-sm sm:text-base md:text-xl lg:text-2xl" />
          </button>

          <button className="ml-5 w-[25px] h-[25px] sm:w-[35px] sm:h-[35px] md:w-[45px] md:h-[45px]  lg:h-[60px] lg:w-[60px]  rounded-full flex-center bg-white transition-all ease-in-out duration-300 hover:bg-[--blue-dark] hover:text-white">
            <FaArrowRight className="text-sm sm:text-base md:text-xl lg:text-2xl" />
          </button>
        </div>
      </div>
      <div className="w-[90%] mx-auto overflow-x-hidden px-6 flex justify-center item-center py-12 gap-6">
        <TestimonialCard
          imageRef={testimonial1}
          name="Don Felix"
          role="CEO-Ralph Empire"
          comment="NesMasPoint is a game-changer in the realm of business management software, offering a comprehensive solution that caters to the diverse needs of modern SMBs. It has really been helpful to my business."
        />

        <TestimonialCard
          imageRef={testimonial2}
          name="Debby Praise"
          role="CEO-gendy Touch"
          comment="NesMasPoint has truly been helpful to my business, revolutionising the way I manage various aspects of operations. The software's user-friendly interface has simplified the often complex task of business management, allowing me to navigate through different modules effortlessly."
        />
      </div>
    </div>
  );
};

export default Testimonial;
